<template>
  <div class="mcheo-card mcheo-payment payment-options-container">
    <div
      class="mcheo-title mshe-wrapper"
      :class="{ font: IS_RW }"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PWA_15145 }}
      <span
        class="text-error j-payment-tips mshe-hide"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_15092 }}
      </span>
    </div>
    <ul
      v-if="paymentMethods.length"
      v-ada-radio-group
      class="j-payment-method mcheo-payment__methods"
      :class="{ 'no-select-payment': noSelectPayment }"
    >
      <div
        v-show="paymentList.showLoading"
        class="payment-loading"
      >
        <div class="loading-cover"></div>
        <s-loading :show="true" />
      </div>

      <template v-if="checkout.results">
        <payment-options-item
          v-for="(item, idx) in paymentMethods"
          :key="item.code"
          :product-type="productType"
          :item="item"
          :idx="idx"
          :is-show-view-more="isShowViewMore"
          :ppgv-account-info="ppgvAccountInfo"
          :after-pay-account-info="afterPayAccountInfo"
          :signup-account-info="signupAccountInfo"
          :checkout="checkout"
          :status="status"
          :not-support-cod-reason="notSupportCodReason"
          :banks-config="banksConfig"
          :payment-display-again="paymentDisplayAgain"
          :paypal-ga-vault="paypalGaVault"
          :afterPayVault="afterPayVault"
          :signupPayVault="signupPayVault"
          :language="language"
          :payment-icon-prefix="paymentIconPrefix"
          :google-valid="googleValid"
          :price="price"
          :installment-info="installmentInfo"
          :payment-option="paymentOption"
          :card-bin-discount-desc="cardBinDiscountDesc"
          :need-pay-inline-payment-methods="needPayInlinePaymentMethods"
          :cod-display="codDisplay"
          :show-widget-form="showWidgetForm"
          :locals="locals"
          :default-select-token-pay="defaultSelectTokenPay"
          :total-price-amount="totalPriceAmount"
          :paypal-message-show="paypalMessageShow"
          @choosePayment="choosePayment"
          @showMaskTips="showMaskTips"
          @startSelectBank="handleStartSelectBank"
          @handleTogglePpgaType="handleTogglePpgaType"
          @handleToggleAfterPayType="handleToggleAfterPayType"
          @handleToggleSignupType="handleToggleSignupType"
          @handlePaymentOptionsOper="handlePaymentOptionsOper"
          @clickPaypalGaType="clickPaypalGaType"
          @showTokenListDrawer="showTokenListDrawer"
          @changeDefaultSelectTokenPay="changeDefaultSelectTokenPay"
        />
        <div
          v-if="isShowViewMore"
          v-expose="{
            id: '1-11-1-129',
            data: {
              payment_method: 'view_more',
              is_vaulting: '-',
              is_folded: 0,
              is_pay_promotion: 0,
              is_default: '-',
              default_type: '-',
              sequence: checkout?.results?.originFoldedPosition + 1
            }
          }"
          class="mcheo-payment__more"
          @click.stop="toUnfold"
        >
          <p class="fold-text">
            {{ language.SHEIN_KEY_PWA_21350 }}
          </p>
          <div
            v-if="isFoldWithIcon && foldIconList.length > 0"
            class="fold-icon"
          >
            <ul>
              <!-- 在大部分情况下最多只能展示9-10个icon，这里简单处理，取最大9个展示 -->
              <li
                v-for="(icon, index) in (foldIconList.length > 9 ? foldIconList.slice(0, 8) : foldIconList.slice(0, 9))"
                :key="`${icon}-${index}`"
                class="fold-icon-item"
              >
                <img :src="transformImg({ img: icon })" />
              </li>
              <li
                v-if="foldIconList.length > 9"
                class="fold-icon-item fold-icon-more"
              >
                <Icon
                  name="sui_icon_more_20px"
                />
              </li>
            </ul>
          </div>
          <i
            class="sui_icon_more_down_12px_2"
            :class="[paymentIconPrefix]"
          ></i>
        </div>
      </template>
    </ul>

    <ClientOnly>
      <choose-token-pay 
        v-model="isShowTokenPayDrawer"
        :checkout="checkout"
        :language="language"
        :default-select-token-pay="defaultSelectTokenPay"
        :logo-list="paymentLogoList"
        @changeDefaultSelectTokenPay="changeDefaultSelectTokenPay"
        @handlePaymentOptionsOper="handlePaymentOptionsOper"
      />
    </ClientOnly>
    <ClientOnly>
      <SignupPayOneTimePayDrawer
        :language="language"
        :vault="signupPayVault[signupDrawerConfig?.paymentCode]"
        :account-info="signupAccountInfo[signupDrawerConfig?.paymentCode]"
        :paymentCode="signupDrawerConfig?.paymentCode"
        :show="signupDrawerConfig?.show"
        :is-force-remember="isAutoRenewal"
        @confirm="handleToggleOneTimePay"
      />
    </ClientOnly>
  </div>
</template>

<script>
/*
 * 支付方式组件。使用范围普通订单下单页，礼品卡下单页、二次下单页
 *
 * */
import ClientOnly from 'vue-client-only'
import Vue from 'vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  Radio,
  Checkbox,
  Label,
  Drawer,
  Loading as SLoading,
} from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { template, isNumber, transformImg } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { PPGA_CHECK_TYPE, AFTERPAY_CHECK_TYPE, SIGNUP_CHECK_TYPE } from 'public/src/pages/common/constants'
import paymentOptionsItem from './payment_options_item.vue'
import paymentOptionsMixin from './payment_options_mixin.js'
import ltspcPayments from '../components/ltspc_payments'
;[Radio, Checkbox, Label, Drawer].forEach(c => Vue.use(c))

import ChooseTokenPay from './choose_token_pay.vue' 

Vue.component('ClientOnly', ClientOnly)

daEventCenter.addSubscriber({ modulecode: '2-31' })

const ltspcCodes = Object.values(ltspcPayments.payCodes)

export default {
  name: 'PaymentOptions',
  components: {
    SLoading,
    paymentOptionsItem,
    ChooseTokenPay,
    Icon,
    SignupPayOneTimePayDrawer: () =>
      import(
        /* webpackChunkName: "checkout-signup-one-time-pay-drawer" */ '../vue_tpls/SignupPayOneTimePayDrawer.vue'
      ),
  },
  mixins: [paymentOptionsMixin],
  /* eslint-disable */
  props: [
    'googleValid',
    'clickPayment',
    'handleStartSelectBank',
    'showMaskTips',
    'language',
    'checkout',
    'paymentList',
    'codDisplay',
    'notSupportCodReason',
    'price',
    'status',
    'paymentOption',
    'banksConfig',
    'noSelectPayment',
    'paymentDisplayAgain',
    'showWidgetForm',
    'needPayInlinePaymentMethods',
    'paypalGaVault',
    'afterPayVault',
    'signupPayVault',
    'ppgvAccountInfo',
    'afterPayAccountInfo',
    'signupAccountInfo',
    'pageName',
    'checkoutScene',
    'iconPrefix',
    'locals',
    'productType',
    'defaultSelectTokenPay',
    'handleSelectTokenPay',
    'paymentLogoList',
    'signupDrawerConfig',
    'totalPriceAmount',
    'paypalMessageShow',
  ],
  /* eslint-enable */
  data: function () {
    return {
      installmentInfo: {}, // 分期信息
      installmentInfoCache: {}, // 分期信息缓存
      installmentInfoCacheKey: '',
      requesting: false,
      PPGA_CHECK_TYPE,
      anglesTriggerLock: false,
      isShowTokenPayDrawer: false,
    }
  },
  computed: {
    ...mapGetters('checkout', ['productAutoInfo', 'xtraAutoController']),

    ...mapState('checkout', [
      'coupon',
      'usableCouponList',
      'couponBinList',
      'installmentSelectBin'
    ]),

    checkoutPaymentMethods() {
      const { paymentMethods = [], folded_position } = this.checkout.results || {}
      if (this.isUnfold) {
        return paymentMethods
      }
      return paymentMethods.filter((_, index) => index < folded_position)
    },
    isUnfold() {
      const { folded_position } = this.checkout.results || {}
      return !this.isFoldPayment || !isNumber(folded_position) || folded_position <= 0
    },
    isShowViewMore() {
      return (
        this.checkoutPaymentMethods?.length !==
        this.checkout?.results?.paymentMethods?.length
      )
    },
    mall_caculate_info() {
      return (
        this.checkout.mall_caculate_info || this.checkout.caculate_info || {}
      )
    },
    paymentMethodLimitInfo() {
      return this.mall_caculate_info?.paymentMethodLimitInfo || {}
    },
    // 卡bin优惠信息
    cardBinDiscountDesc() {
      // 当命中多个卡bin优惠，不区分命中的类型
      if (this.checkout?.results?.bin_discount_list?.length > 1) {
        const lengthObj = {
          2: this.language?.SHEIN_KEY_PWA_26144,
          3: this.language?.SHEIN_KEY_PWA_26145
        }

        const binDiscountLen = this.checkout?.results?.bin_discount_list?.length > 3 ? 3 : this.checkout?.results?.bin_discount_list?.length

        return template(
          ...this.checkout?.results?.bin_discount_list?.map(
            item => item?.bank_name
          ),
          lengthObj[binDiscountLen]
        )
      }

      const {
        bank_name = '',
        discount_amount = {},
        discount_percentage = 0,
        discount_type = 0,
        threshold_amount = {},
        max_discount_amount = {},
        max_random_amount = {},
        min_random_amount = {}
      } = this.checkout?.results?.bin_discount_list?.[0] ||
      this.checkout?.results?.bin_discount_info ||
      {}
      if (discount_type === 1) {
        return template(
          discount_amount?.amountWithSymbol,
          threshold_amount?.amountWithSymbol,
          bank_name || '',
          this.language?.SHEIN_KEY_PWA_24447
        )
      }
      if (discount_type === 2) {
        const maxDiscountAmount = max_discount_amount?.amount
        const thresholdAmount = threshold_amount?.amount
        // 折扣--无门槛
        if (thresholdAmount == 0) {
          return template(
            maxDiscountAmount > 0
              ? max_discount_amount.amountWithSymbol
              : discount_percentage || '',
            bank_name || '',
            maxDiscountAmount > 0
              ? this.language?.SHEIN_KEY_PWA_24525
              : this.language?.SHEIN_KEY_PWA_24526
          )
        }
        return template(
          maxDiscountAmount > 0
            ? max_discount_amount.amountWithSymbol
            : discount_percentage || '',
          bank_name || '',
          threshold_amount?.amountWithSymbol,
          maxDiscountAmount > 0
            ? this.language?.SHEIN_KEY_PWA_24453
            : this.language?.SHEIN_KEY_PWA_24448
        )
      }
      // 随机立减
      if (discount_type == 3 && this.isShowBinRandomDiscountABT) {
        const thresholdAmount = threshold_amount?.amount
        // 折扣--无门槛
        if (thresholdAmount == 0) {
          return template(
            min_random_amount?.amountWithSymbol,
            max_random_amount?.amountWithSymbol,
            bank_name || '',
            bank_name
              ? this.language?.SHEIN_KEY_PWA_28692
              : this.language?.SHEIN_KEY_PWA_24866
          )
        }
        return template(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          threshold_amount?.amountWithSymbol,
          bank_name || '',
          bank_name
            ? this.language?.SHEIN_KEY_PWA_28691
            : this.language?.SHEIN_KEY_PWA_24937
        )
      }
      return ''
    },
    isShowCardBinDiscountABT() {
      const { BinDiscountdiscount = {} } = this.checkout?.abtInfo || {}
      return BinDiscountdiscount?.param?.BinDiscountShow === 'Show'
    },
    // 卡bin随机立减展示
    isShowBinRandomDiscountABT() {
      const { BinRandomDiscount = {} } = this.checkout?.abtInfo || {}
      return BinRandomDiscount?.param?.BinRandomShow === 'Show'
    },
    isClient() {
      return typeof window != 'undefined'
    },
    // 购物车paypal checkout需求，需要在url中带入参数foldPayment=1折叠支付方式
    isFoldPaymentFromUrl() {
      return this.checkout?.isFoldPaymentFromUrl || false
    },
    isFoldPayment() {
      let isFold = false
      if (this.pageName !== 'checkout') return isFold

      isFold = this.checkout.abtInfo?.foldpayment?.param === 'fold_payment' || this.isFoldPaymentFromUrl
      return isFold
    },
    isFoldWithIcon() {
      const { foldPaymentLogo = {} } = this.checkout?.abtInfo || {}
      return foldPaymentLogo?.param?.foldPaymentLogoStatus == 1
    },
    foldIconList() {
      let iconList = []

      if (this.isFoldWithIcon) {
        // 支付icon折叠展示，产品说不会有重复的logo，按最简单的逻辑处理 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1377258399
        const { paymentMethods = [], folded_position } =
        this.checkout.results || {}

        iconList = paymentMethods.reduce(
          (pre, next, index) => {
            if (index >= folded_position) {
              if (ltspcCodes.includes(next.code) && next.card_logo_list?.length) {
                // 卡支付方式且卡组logo不为空, 取卡组logo前三个icon
                const cardIcons = next.card_logo_list.slice(0, 3)
                pre = pre.concat(cardIcons)
              } else {
                pre.push(next?.logo_url)
              }
            }
            return pre
          }, []
        )
      }

      return iconList
    },
    // 随机立减abt
    randomDiscountAbt() {
      return (
        this.checkout?.abtInfo?.PaymentRandomDiscount?.param
          ?.PaymentRandomShow == 'Show'
      )
    },
    paymentIconPrefix() {
      return this.iconPrefix ? this.iconPrefix : 'suiiconfont'
    },
    paymentMethods() {
      return this.checkout?.results?.paymentMethods || []
    },
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },

    routePayCardBinList() {
      return ((this.paymentMethods || []).find(item => item.code === 'routepay-card')?.card_token_list || [])?.map(item => item?.card_bin) || []
    },

    amount() {
      return this.checkout?.mall_caculate_info?.grandTotalPrice?.amount ||
        this.checkout?.results?.carts?.cartInfo?.totalPrice?.amount
    },

    isResetInstallment() {
      return {
        bin: this.installmentSelectBin,
        amount: this.amount,
        countryCode: this.checkout?.default_address?.value,
        orderCurrency: this.checkout?.mall_caculate_info?.orderCurrency?.code
      }
    },

    isAutoRenewal() {
      const primeActivityType = this?.checkout?.results?.primeActivityType
      const { currentProduct } = this.productAutoInfo || {}
      const { currentXtraIsAuto } = this.xtraAutoController || {}
      return primeActivityType == 1 || currentProduct?.product_activity_type == 1 || currentXtraIsAuto
    }
  },
  watch: {
    'checkout.mall_caculate_info': {
      immediate: true,
      handler() {
        if (typeof window === 'undefined') return
        if (this.paymentMethods.length) {
          if (this.checkoutScene === 'buyprime') return
          this.getInstallmentInfo()
        }
      }
    },
    isShowCardBinDiscountABT: {
      immediate: true,
      handler() {
        if (typeof window === 'undefined') return
        if (this.isShowCardBinDiscountABT) {
          const extraData = {}
          let temp_match_payments = []
          let is_discount_type = false
          let discountTypeList = []
          let discount_type_key_list = []
          this.checkout?.results?.bin_discount_list?.forEach(item => {
            if (item?.discount_type != 0) {
              is_discount_type = true

              if (!discount_type_key_list.includes(item.discount_type)) {
                discount_type_key_list.push(item.discount_type)
                let temp_payment_list = this.paymentMethods
                  ?.filter(now_item =>
                    item?.match_payments.includes(now_item.code)
                  )
                  .map(temp_item => temp_item.code)
                  .join('`')

                temp_match_payments = [
                  ...temp_match_payments,
                  temp_payment_list
                ]
                discountTypeList.push(
                  `${item.discount_type}:${temp_payment_list}`
                )
              }
            }
          })

          extraData.is_full = is_discount_type ? 1 : 0

          if (is_discount_type) {
            extraData.payment_list = temp_match_payments.join('`')
            daEventCenter.triggerNotice({
              daId: '1-11-1-118',
              extraData: {
                discountType: discountTypeList.join(',')
              }
            })
          }

          daEventCenter.triggerNotice({
            daId: '1-11-1-117',
            extraData
          })
        }
      }
    },
    'price.onlinePayDiscountInfo': {
      immediate: true,
      handler(val) {
        if (typeof window === 'undefined') return
        if (val && !this.anglesTriggerLock) {
          this.anglesTrigger()
        }
      }
    },

    'usableCouponList': {
      immediate: true,
      deep: true,
      handler() {
        if (typeof window === 'undefined') return
        this.$nextTick(() => {
          this.validateBin()
        })
      }
    },

    'isResetInstallment': {
      handler(newVal, oldVal) {
        if (typeof window === 'undefined') return
        if (newVal?.bin !== oldVal?.bin 
          || newVal?.amount !== oldVal?.amount 
          || newVal?.countryCode !== oldVal?.countryCode
          || newVal?.orderCurrency !== oldVal?.orderCurrency
        ) {
          this.fetchInstallmentInfo()
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    ...mapMutations('checkout', ['assignState']),
    template,
    transformImg,
    toUnfold() {
      const extraData = { payment_method: 'view_more', is_vaulting: '-', is_folded: 0, is_pay_promotion: 0, is_default: '-', default_type: '-', sequence: this.checkout?.results?.originFoldedPosition + 1 }
      daEventCenter.triggerNotice({
        daId: '1-11-1-130',
        extraData
      })
      // daEventCenter.triggerNotice({ daId: '1-11-1-70' })
      this.$emit('unfold')
    },
    paymentDisplay: function (code) {
      if (
        this.paymentDisplayAgain &&
        typeof this.paymentDisplayAgain == 'function'
      ) {
        return this.paymentDisplayAgain(code)
      } else {
        return true
      }
    },

    getInstallmentInfo(opt) {
      const _this = this
      if (this.requesting) return
      // fix giftcard installment error
      if(this.productType == 'giftcard' && !opt) return
      let data = {}
      if (opt) {
        data = opt
      } else {
        data = {
          country: this.checkout?.default_address?.value,
          amount: this.mall_caculate_info?.grandTotalPrice?.amount,
          currency: gbCommonInfo.currency
        }
      }
      const cacheKey = data.country + '_' + data.currency + '_' + data.amount
      if (this.installmentInfoCache[cacheKey]) {
        if (this.installmentInfoCacheKey != cacheKey) {
          _this.installmentInfoCacheKey = cacheKey
          Vue.set(this, 'installmentInfo', this.installmentInfoCache[cacheKey])
          _this.$emit('update-intallment-info', _this.installmentInfo)
        }
      } else {
        this.requesting = true
        schttp({
          method: 'POST',
          url: '/api/checkout/installmentDetail/get',
          data
        })
          .then(res => {
            if (res && res.code == 0) {
              if (
                res.info &&
                res.info.front_show_list &&
                res.info.front_show_list.length
              ) {
                const front_list_items = {}
                res.info.front_show_list.forEach(item => {
                  front_list_items[item.payment_code] = item
                })
                res.info.front_list_items = front_list_items
              }

              Vue.set(_this, 'installmentInfo', res.info || {})
              _this.installmentInfoCache[cacheKey] = res.info || {}
              _this.installmentInfoCacheKey = cacheKey
            } else {
              Vue.set(_this, 'installmentInfo', {})
            }
            _this.requesting = false
            _this.$emit('update-intallment-info', _this.installmentInfo)
          })
          .catch(() => {
            _this.requesting = false
            Vue.set(_this, 'installmentInfo', {})
          })
      }
    },

    clickDes(item) {
      const des = this.getPaymentDes(item)
      des && this.showMaskTips(des)
    },

    choosePayment(opts) {
      const { item, isPaymentSuggestionTriggerClick, index } = opts
      if (item.isAllBusiness) return this.showMaskTips(item.gray_description)

      // 币种限制
      if (!this.paymentDisplay(item.code)) {
        this.showMaskTips(this.language.SHEIN_KEY_PWA_17728)
        return
      }
      if (item && item.enabled != 1) {
        this.clickDes(item)
        return
      }
      const { folded_position, originFoldedPosition } =
        this.checkout.results || {}
      if (folded_position === -1 && opts.index >= originFoldedPosition) {
        daEventCenter.triggerNotice({ daId: '1-11-1-72' })
      }
      this.clickPayment({
        id: item.id,
        code: item.code,
        item: item,
        isPaymentSuggestionTriggerClick,
        paypalGaVault: this?.paypalGaVault || {},
        afterPayVault: this?.afterPayVault || {},
        onlinePayDiscountInfoValue: this.price?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[item?.code] || null,
        idx: index
      })
    },
    //展示paypal ga 签约切换弹窗
    clickPaypalGaType(paymentCode) {
      this.$emit('click-signup-one-time-arrow', paymentCode)
    },
    //确认paypal ga 签约类型
    // confirmPPGVType() {
    //   this.$emit('confirm-ppgv-type', true)
    // },
    handlePaymentOptionsOper(type = '', data = {}) {
      if (!type) return
      this.$emit('payment-options-oper', { type, data })
    },
    // 支付方式全量上报
    anglesTrigger() {
      const pament_onLinePay = Object.entries(
        this.price?.onlinePayDiscountInfo?.onlinePayDiscountValue || {}
      ).map(r => {
        return {
          ...r[1],
          paymentCode: r[0]
        }
      })

      // 判断是否存在卡bin随机立减的配置数据
      const matchBinRandomDiscountQualificationPayments = () => {
        const binDiscountList = this.checkout?.results?.bin_discount_list || []
        if (binDiscountList.length > 1) return []
        const { discount_type = 0, match_payments = [] } =
          binDiscountList?.[0] || {}
        return discount_type === 3 ? match_payments : []
      }

      // 符合随机立减 不受abt影响 expose_randomdiscount_abt
      const qualificationsPay = () => {
        // 一个符合随机立减 即为1，否则为0
        const full_result = pament_onLinePay.some(s => s.discountType == 2)
          ? 1
          : 0
        const payment_list_result = pament_onLinePay
          .filter(f => f.discountType == 2)
          .reduce(
            (pre, next) => String(pre) + String(next.paymentCode) + '`',
            ''
          )
        daEventCenter.triggerNotice({
          daId: '1-11-1-144',
          extraData: {
            is_full: full_result,
            payment_list: payment_list_result
          }
        })
      }
      // 随机立减文案，受abt影响 expose_randomdiscount
      const discountText = () => {
        const payment_list_result = pament_onLinePay
          .filter(f => f.discountType == 2)
          .reduce(
            (pre, next) => String(pre) + String(next.paymentCode) + '`',
            ''
          )
        daEventCenter.triggerNotice({
          daId: '1-11-1-145',
          extraData: {
            payment_list: payment_list_result
          }
        })
      }

      const matchBinRandomDiscountPaymentsResult =
        matchBinRandomDiscountQualificationPayments()

      if (pament_onLinePay.length) {
        qualificationsPay()
        this.randomDiscountAbt && discountText()
        this.anglesTriggerLock = true
      } else if (matchBinRandomDiscountPaymentsResult.length) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-144',
          extraData: {
            is_full: 1,
            payment_list: matchBinRandomDiscountPaymentsResult.join('`')
          }
        })
        const isMatchExposeBinRandomDiscountDesc = !!this.cardBinDiscountDesc
        if (isMatchExposeBinRandomDiscountDesc) {
          daEventCenter.triggerNotice({
            daId: '1-11-1-145',
            extraData: {
              payment_list: matchBinRandomDiscountPaymentsResult.join('`')
            }
          })
        }
      } else {
        daEventCenter.triggerNotice({
          daId: '1-11-1-144',
          extraData: {
            is_full: 0,
            payment_list: ''
          }
        })
      }
    },
    handleTogglePpgaType() {
      this.paypalGaVault.radioType =
        this.paypalGaVault.radioType === PPGA_CHECK_TYPE.CHECKED
          ? PPGA_CHECK_TYPE.UNCHECKED
          : PPGA_CHECK_TYPE.CHECKED
      this.$emit('confirm-ppgv-type')
    },
    handleToggleAfterPayType() {
      this.afterPayVault.radioType =
        this.afterPayVault.radioType === AFTERPAY_CHECK_TYPE.CHECKED
          ? AFTERPAY_CHECK_TYPE.UNCHECKED
          : AFTERPAY_CHECK_TYPE.CHECKED
      this.$emit('confirm-afterpay-type')
    },
    handleToggleSignupType(paymentCode) {
      if (this.signupPayVault[paymentCode]) {
        this.signupPayVault[paymentCode].radioType =
          this.signupPayVault[paymentCode].radioType === SIGNUP_CHECK_TYPE.CHECKED
            ? SIGNUP_CHECK_TYPE.UNCHECKED
            : SIGNUP_CHECK_TYPE.CHECKED
      }
      this.$emit('confirm-signup-pay-type', paymentCode)
    },
    handleToggleOneTimePay({ paymentCode, selected, isClose = false }) {
      if (selected) {
        const tostLanguage = this.language?.SHEIN_KEY_PWA_31535
        if (this.isAutoRenewal) {
          return this.$toast( tostLanguage )
        }
      }
      this.$emit('confirm-signup-one-time-pay', {
        paymentCode,
        selected,
        isClose
      })
    },

    showTokenListDrawer() {
      this.isShowTokenPayDrawer = true
    },

    changeDefaultSelectTokenPay(item) {
      this.handleSelectTokenPay && this.handleSelectTokenPay(item)
    },

    arrayContainsAll(arr, target) {
      return target.every(element => arr.includes(element))
    },

    // 获取券支持的卡bin
    async validateBin() {
      const nowCouponsList = (this.usableCouponList || [])?.filter(item => item?.card?.card_codes?.length > 0)?.map(item => item?.coupon)

      if (nowCouponsList?.length === 0) return
      const isHad = this.arrayContainsAll(this.couponBinList.map(item => item?.coupon_code), nowCouponsList)
      if (isHad && this.couponBinList?.length > 0) return
      try {
        const data = {
          coupon_codes: nowCouponsList,
          bin_list: this.routePayCardBinList
        }

        const res = await schttp({
          method: 'POST',
          url: '/api/coupon/validBin/get',
          data
        })
        

        if (res?.code == 0) {
          this.$store && this.assignState({
            couponBinList: res?.info?.coupon_bin_list || []
          })
        }
      } catch (error) {
        return error
      }
    },

    async fetchInstallmentInfo() {
      const isCanGetInstallment = this.checkout?.default_address?.value?.length > 0 && this.checkout?.mall_caculate_info?.orderCurrency?.code?.length > 0
      if (!isCanGetInstallment) return
      const res = await schttp({
        url: '/api/checkout/installmentInfo/get',
        params: {
          paymentCode: 'routepay-cardinstallment',
          countryCode: this.checkout?.default_address?.value || '', // 国家
          orderCurrency: this.checkout?.mall_caculate_info?.orderCurrency?.code, // 币种
          orderAmount: this.amount, // 金额
          cardBin: this.installmentSelectBin || ''
        }
      })

      if (res.code === '0') {
        const tempInstallmentList = res.info?.installments || []
        const recommend_installments = res.info?.recommend_installments
        const recommend_reason = res.info?.recommend_reason
        if ((recommend_installments ?? null) !== null) {
          tempInstallmentList.forEach(item => {
            item.recommend_installments = recommend_installments
            item.recommend_reason = recommend_reason
          })
        }
        this.$store && this.assignState({
          binInstallmentListInfo: res.info?.installments || []
        })
      }
    }

  },
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.mcheo-card .card-left {
  padding: 0;
}
.mcheo-payment .payment-footer {
  .margin-l(0.853333rem);
  // margin-top: .213rem;
}
.font {
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}
.mcheo-payment {
  margin-top: 0.26666rem;

  &__more {
    padding: 0.4rem 0;
    color: @sui_color_gray_dark3;
    text-align: center;
  }
  &__methods,
  &__item {
    &.fold {
      position: absolute;
      top: 0;
      left: -1000%;
    }
  }
}
.payment-option-effiency {
  .mcheo-payment__more {
    padding: 20/75rem 24/75rem 20/75rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-footer {
    .margin-l(64/75rem);
  }
  .fold-text {
    flex-shrink: 0;
    .margin-r(4/75rem);
  }
  .fold-icon {
    overflow: hidden;
    margin: 0 8/75rem 0 12/75rem;

    > ul {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .fold-icon-item {
      width: .64rem;
      height: 32/75rem;
      display: inline-block;
      .margin-l(8/75rem);
      flex-shrink: 0;

      &:first-child {
        .margin-l(0);
      }

      > img {
        width: 100%;
        height: 100%;
        vertical-align: baseline;
      }
    }
    .fold-icon-more {
      background-color: @sui_color_gray_weak1;
      border-radius: 4/75rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.discount-tips-item {
  display: inline-block;
  color: @sui_color_promo_dark;
  padding: 4/75rem 8/75rem;
  margin-bottom: 6/75rem;
  .font-dpr(24px);
  line-height: 1;
  .border-dpr(border, 1px, @sui_color_prompt_unusual);
  background-color: #fff6f3;
  .icon {
    .font-dpr(24px) !important; /* stylelint-disable-line */
    line-height: 1;
    padding-left: 4/75rem;
    color: @sui_color_gray_light1;
  }
}

.mcheo-card {
  .mcheo-title {
    text-transform: capitalize;
    color: #222;
    background: #fff;
    padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    font-weight: 700;
    padding-top: 0.32rem !important; /* stylelint-disable-line declaration-no-important */
    & when (@IS_RW) {
      font-weight: 400;
    }
    .text-error {
      color: @sui_color_unusual;
      .margin-l(0.2rem);
      text-transform: initial;
    }
  }
  > ul {
    background: #fff;
    position: relative;
    .padding-l(24/75rem);
    > li {
      position: relative;
      padding: 20/75rem 0;
      .padding-r(24/75rem);
      .align-center();
      .border-dpr(border-bottom,2px,#e5e5e5);
      &:last-child {
        border: none;
      }
      &.disabled {
        color: #999;
      }
      .payment-discount-zero {
        .margin-l(6px);
        border: .5px solid #ffe1db;
      }
      .payment-discount-tip {
        color: @sui_color_highlight;
        .font-dpr(24px);
        margin-top: 0;
      }
      .ppgv-arrow {
        height: 32px;
        position: absolute;
        margin-top: -16px;
        font-size: 16px;
        color: #999;
        top: 0.62rem;
        .right(0.32rem);
        .padding-l(0.8rem); // 增大点击区域
      }
      .payment-item-checkbox{
        width: 48/75rem;
        height: 48/75rem;
      }
      &.more-padding-right {
        .padding-r(60/75rem);
      }
    }

    .payment-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      .left(0);
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      .loading-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #fff;
        opacity: 0.5;
      }
      .loading-ball {
        position: absolute;
        top: 50%;
        margin-top: -0.25rem;
        .left(50%);
        .margin-l(-1.14rem);
      }
    }
  }

  .card-left {
    width: 100%;
    // .padding-l(.2rem);
    .flexbox();
    .align-center();
    .icon-pro-help {
      font-size: 16px;
      color: #999;
      vertical-align: middle;
    }
    .check-title {
      .fl();
    }
    .payment-src {
      .margin-r(0.2rem);
      width: 0.8rem;
      height: 0.52rem;
    }
    > span {
      display: block;
      font-size: 14px;
      > i {
        .margin-l(0.2rem);
      }
      &.check-disabled {
        color: #222;
        opacity: 0.3;
      }
    }
    > i {
      background-image: url(/pwa_dist/images/checkcard-e488f6ed3d.png);
      background-size: 2.8rem auto;
      display: block;
      .margin-r(0.2rem);
      &.check-disabled {
        filter: grayscale(1);
      }
    }
    .off-tips {
      color: #e64545;
      .font-dpr(22px);
    }
    .icon-tips {
      .padding-l(0.106666rem);
      color: @sui_color_gray_light1;
      text-decoration: none;
    }
    .bank-contain {
      flex: 1;
      .txt-r();
      .selected-bank-area {
        .flexbox();
        align-items: center;
        .selected-bank-text {
          word-break: break-word;
          .margin-r(0.0533rem);
          color: @sui_color_gray_dark2;
          .font-dpr(24px);
          flex: 1;
        }
        .icon2 {
          .margin-r(0);
          .font-dpr(32px);
          color: @sui_color_gray_light1;
        }
        .txt-error {
            color: @sui_color_unusual;
        }
      }
    }
    &.ssr-common {
      margin-left: 24/75rem;
    }
    &.ssr-se {
      margin-left: 16/75rem;
    }

    .text-error {
      color: @sui_color_unusual;
      .font-dpr(22px);
    }

    .left-drawer{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #959595;

      .more-discount {
        color: #767676;
        font-size: 14px;
        font-family: SF UI Text;
        font-weight: 400;
        word-wrap: break-word;
      }

      .red-point {
        background: #FA6338;
        width: 14/75rem;
        height: 14/75rem;
        border-radius: 50%;
        margin: 0 4/75rem 0 8/75rem;
      }
    }
  }
  .paytm-upi-tips {
    color: #666;
    .font-dpr(24px);
    padding-left: 1rem;
    padding-bottom: 0.12rem;
    .input-item {
      position: relative;
      padding: 0.1rem 0 0 0;
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      input {
        width: 100%;
        padding: 0 10px;
        height: 36px;
        line-height: 36px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      [class*='iconfont'] {
        font-size: 12px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: none;
      }
      .icon-warn-out {
        color: #d53333;
      }
      .icon-checked {
        color: #5ebd66;
      }
    }
    .error-tips {
      color: #ff411c;
      display: none;
    }
    &.error-input-item {
      input {
        border-color: #ff411c;
      }
      .error-tips {
        display: block;
      }
      [class*='iconfont'] {
        display: block;
      }
    }
    &.succ-input-item {
      [class*='iconfont'] {
        display: block;
      }
    }
  }
}

.card-logo {
  .card-logo-item {
    width: 42/75rem;
    height: 28/75rem;
    display: inline-block;
    .margin-r(12/75rem);
    margin-bottom: 12/75rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: baseline;
    }
  }
}

.extra-tip {
  font-size: 12px;
  color: #666;

  a {
    text-decoration: none;
    display: inline-block;
    .icon-pro {
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
.mshe-hide {
  display: none;
}
.all-discount-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.pay-item-token__bin {
  margin-top: 0.1rem;
}
.discount-percent-sy {
  margin: 0.1rem 0;
}

.ppgv-type-drawer {
  .type-des {
    padding: 0.32rem;
    color: #666;
    background-color: #f6f6f6;
    text-align: center;
    .font-dpr(24px);
  }
  .type-radio {
    border-bottom: 1px solid #f6f6f6;
  }
  .type-item {
    width: 100%;
    height: 1.17333rem;
    .margin-l(0.32rem);
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }
  .confirm-btn {
    padding: 0.2rem 0.32rem;
  }
}

#paypal-bnpl-message{
  margin-top: 12/75rem;
}
</style>
